@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");


.btn-primary{
  @apply text-white px-[30px] py-[8px] rounded-3xl bg-yellow-400 cursor-pointer hover:opacity-70 transition-all duration-500;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
}

.referencesMarker{
  padding: 10px !important;
}

#hero-bg{
  background-image: url("bgg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

}



.font-bordered {
  text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
  1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000 !important;
}

/* Mobile Navbar*/
.hidden-element {
  transform: translateX(-100%);
  max-height: 0 !important ;
  min-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
scale: 0;

}

.show {
  transform: translateX(0);
}












.s130 {
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
  }
  
  .s130 form {
    width: 100%;
    max-width: 790px;
    padding-top: 24vh;
  }
  
  .s130 form .inner-form {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 34px;
    overflow: hidden;
    margin-bottom: 30px;
  }
  
  .s130 form .inner-form .input-field {
    height: 68px;
  }
  
  .s130 form .inner-form .input-field input {
    height: 100%;
    background: transparent;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #000;
  }
  
  .s130 form .inner-form .input-field input.placeholder {
    color: #222;
    font-size: 16px;
  }
  
  .s130 form .inner-form .input-field input:-moz-placeholder {
    color: #222;
    font-size: 16px;
  }
  
  .s130 form .inner-form .input-field input::-webkit-input-placeholder {
    color: #222;
    font-size: 16px;
  }
  
  .s130 form .inner-form .input-field input:hover, .s130 form .inner-form .input-field input:focus {
    box-shadow: none;
    outline: 0;
  }
  
  .s130 form .inner-form .input-field.first-wrap {
    -ms-flex-positive: 1;
        flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    background: rgb(254 240 138);
  }
  
  .s130 form .inner-form .input-field.first-wrap input {
    -ms-flex-positive: 1;
        flex-grow: 1;
  }
  
  .s130 form .inner-form .input-field.first-wrap .svg-wrapper {
    min-width: 80px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
  }
  
  .s130 form .inner-form .input-field.first-wrap svg {
    width: 36px;
    height: 36px;
    fill: #222;
  }
  
  .s130 form .inner-form .input-field.second-wrap {
    min-width: 216px;
  }
  
  .s130 form .inner-form .input-field.second-wrap .btn-search {
    height: 100%;
    width: 100%;
    white-space: nowrap;
    font-size: 16px;
    color: #fff;
    border: 0;
    cursor: pointer;
    position: relative;
    z-index: 0;
    background: rgb(250 204 21);
    transition: all .2s ease-out, color .2s ease-out;
    font-weight: 300;
  }
  
  .s130 form .inner-form .input-field.second-wrap .btn-search:hover {
    background: rgb(250 204 21);
  }
  
  .s130 form .inner-form .input-field.second-wrap .btn-search:focus {
    outline: 0;
    box-shadow: none;
  }
  
  .s130 form .info {
    font-size: 15px;
    color: #ccc;
    padding-left: 26px;
  }
  
  @media screen and (max-width: 992px) {
    .s130 form .inner-form .input-field {
      height: 50px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .s130 form .inner-form .input-field.first-wrap .svg-wrapper {
      min-width: 40px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center;
      padding: 0 10px;
    }
    .s130 form .inner-form .input-field.first-wrap svg {
      width: 26px;
      height: 26px;
      fill: #222;
    }
    .s130 form .inner-form .input-field.second-wrap {
      min-width: 100px;
    }
    .s130 form .inner-form .input-field.second-wrap .btn-search {
      font-size: 13px;
    }
  }
  
  